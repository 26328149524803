<template>
  <div class="body add-business">
    <el-form
      :model="addForm"
      :rules="rule"
      ref="addForm"
      :inline="true"
      label-width="110px"
      label-position="left"
      :disabled="disabled"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="上级节点：" prop="parentName">
        <el-input v-model.trim="addForm.parentName" disabled></el-input>
      </el-form-item>
      <el-form-item label="节点名称：" prop="name">
        <el-input
          v-model.trim="addForm.name"
          placeholder="请输入节点名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="节点类型：" prop="type">
        <el-select
          v-model="addForm.type"
          placeholder="请选择"
          :disabled="!typeVisible"
        >
          <el-option label="公司" :value="1"></el-option>
          <el-option label="车队" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属系统" prop="system" v-if="addForm.type == 2">
        <el-select
          v-model="addForm.system"
          clearable
          placeholder="请选择所属系统"
        >
          <el-option
            v-for="item in systemList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构代码：" prop="orgCa" v-if="addForm.type < 2">
        <el-input v-model.trim="addForm.orgCa" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item
        label="法人代表："
        prop="corporation"
        v-if="addForm.type < 2"
      >
        <el-input
          v-model.trim="addForm.corporation"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="地址：" prop="address" v-if="addForm.type < 2">
        <el-input
          v-model.trim="addForm.address"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="经度：" prop="longitude" v-if="addForm.type < 2">
        <el-input
          v-model.trim="addForm.longitude"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="纬度：" prop="latitude" v-if="addForm.type < 2">
        <el-input
          v-model.trim="addForm.latitude"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="服务单位："
        prop="serviceOrgan"
        v-if="addForm.type < 2"
      >
        <el-input
          v-model.trim="addForm.serviceOrgan"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="投诉电话："
        prop="complaintTel"
        v-if="addForm.type < 2"
      >
        <el-input
          v-model.trim="addForm.complaintTel"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="公司电话："
        prop="companyPhone"
        v-if="addForm.type < 2"
      >
        <el-input
          v-model.trim="addForm.companyPhone"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人：" prop="linkMan">
        <el-input
          v-model.trim="addForm.linkMan"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" prop="phone">
        <el-input v-model.trim="addForm.phone" placeholder="请输入"></el-input>
      </el-form-item>
      <!-- 工单负责企业 -->
      <el-form-item label="工单负责企业：" prop="">
        <el-select v-model="addForm.wxConfigId" clearable placeholder="请选择">
          <el-option
            v-for="item in wxConfigList"
            :label="item.companyName"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- 车载监控设备自动监测及报警系统项目 -->
    <div style="padding-left:1.5vh">设备故障报警设置：</div>
    <el-form
      :model="addForm"
      :rules="rule"
      ref="addForm2"
      :inline="true"
      label-width="110px"
      label-position="left"
      :disabled="disabled"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="故障负责人：" prop="">
        <el-select
          v-model="addForm.faultOwner"
          multiple
          filterable
          remote
          collapse-tags
          placeholder
          :remote-method="getFaultOwner"
          :loading="faultOwnerLoading"
          :reserve-keyword="false"
        >
          <el-option
            v-for="item in faultOwnerList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报警类型：" prop="">
        <el-select
          v-model="addForm.faultAlarmType"
          multiple
          clearable
          placeholder
        >
          <el-option
            v-for="item in faultAlarmTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          >
            <span style="padding-right: 20px">{{ item.dictValue }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- 数电发票-出租车开票配置 -->
    <template v-if="addForm.type < 2 || !addForm.type">
      <div style="padding-left:1.5vh">开票设置：</div>
      <el-form
        :model="addForm"
        :rules="rule"
        ref="addForm3"
        :inline="true"
        label-width="110px"
        label-position="left"
        :disabled="disabled"
        class="demo-form-inline dialog-form dialog-form-inline"
      >
        <el-form-item label="是否允许开票：">
          <el-select v-model="addForm.invoiceStatus" clearable placeholder>
            <el-option
              v-for="item in invoiceStatusList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            >
              <span style="padding-right: 20px">{{ item.dictValue }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票限额：" class="invoice-limit-input">
          <el-input
            v-model.trim="addForm.invoiceLimit"
            @input="
              addForm.invoiceLimit = addForm.invoiceLimit.replace(
                /^(0+)|[^\d]+/g,
                ''
              )
            "
          >
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item>
        <el-form-item label="单张限额：" class="invoice-limit-input">
          <el-input
            v-model.trim="addForm.oneInvoiceLimit"
            @input="
              addForm.oneInvoiceLimit = addForm.oneInvoiceLimit.replace(
                /^(0+)|[^\d]+/g,
                ''
              )
            "
          >
            <template slot="append">元/张</template>
          </el-input>
        </el-form-item>
        <el-form-item label="开票类目：">
          <el-select v-model="addForm.businessType" clearable placeholder>
            <el-option
              v-for="item in businessTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            >
              <span style="padding-right: 20px">{{ item.dictValue }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <!-- 村通客运-小程序设置 -->
    <template v-if="addForm.type < 2 || !addForm.type">
      <div style="padding-left:1.5vh">小程序设置：</div>
      <el-form
        :model="addForm"
        :rules="rule"
        ref="addForm4"
        :inline="true"
        label-width="110px"
        label-position="left"
        :disabled="disabled"
        class="demo-form-inline dialog-form dialog-form-inline"
      >
      <el-form-item label="小程序APPID：" prop="appId">
        <el-input v-model.trim="addForm.appId"></el-input>
      </el-form-item>
      <el-form-item label="小程序secret：" prop="secret">
        <el-input v-model.trim="addForm.secret"></el-input>
      </el-form-item>
      <el-form-item label="邀请url：" prop="inviteUrl">
        <el-input v-model.trim="addForm.inviteUrl"></el-input>
      </el-form-item>
      </el-form>
    </template>
  </div>
</template>

<script>
import {
  checkName,
  checkNodeName,
  checkPhone,
  checkLandLine,
  checkLong,
  checkLat
} from "@/common/utils/index.js";
import {
  insertCompany,
  updateCompany,
  queryDictsByCodes,
  getCompanyDetailLabels,
  getWxConfigList,
  getUserList
} from "@/api/lib/api.js";

export default {
  props: {
    addForm: {
      type: Object,
      default: function(params) {
        return {};
      }
    },
    isEdit: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      typeVisible: true,
      systemList: [],
      wxConfigList: [],
      // 故障负责人loading
      faultOwnerLoading: false,
      // 故障负责人
      faultOwnerList: [],
      faultOwnerParams: {
        currentPage: 1,
        pageSize: 100,
        userName: ""
      },
      // 报警类型
      faultAlarmTypeList: [
        {
          dictValue: "储存单元",
          dictCode: "3003"
        },
        {
          dictValue: "GNSS 天线未接或被剪断",
          dictCode: "3031"
        },
        {
          dictValue: "GNSS 天线短路",
          dictCode: "3032"
        }
      ],
      invoiceStatusList: [
        { dictValue: "否", dictCode: 0 },
        { dictValue: "是", dictCode: 1 }
      ],
      businessTypeList: [
        { dictValue: "出租车服务", dictCode: 11 },
        { dictValue: "定制客运", dictCode: 1 },
        { dictValue: "班线客运", dictCode: 2 }
      ],
      rule: {
        name: [
          { required: true, message: "请输入节点名称", trigger: "blur" },
          { validator: checkNodeName, trigger: "blur" }
        ],
        type: [
          { required: true, message: "请选择节点类型", trigger: "change" }
        ],
        system: [
          { required: true, message: "请选择所属系统", trigger: "change" }
        ],
        linkMan: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ],
        complaintTel: [{ validator: checkLandLine, trigger: "blur" }],
        longitude: [{ validator: checkLong, trigger: "blur" }],
        latitude: [{ validator: checkLat, trigger: "blur" }]
      }
    };
  },
  watch: {
    "addForm.type"(v) {
      if (this.disabled) return;
      this.$set(this.addForm, "invoiceStatus", null);
      this.$set(this.addForm, "invoiceLimit", null);
      this.$set(this.addForm, "oneInvoiceLimit", null);
      this.$set(this.addForm, "businessType", null);
    }
  },
  methods: {
    // 获取故障负责人
    getFaultOwner(query) {
      if (query !== "") {
        this.faultOwnerLoading = true;
        this.faultOwnerParams.userName = query;
        setTimeout(() => {
          this.faultOwnerLoading = false;
          getUserList(this.faultOwnerParams).then(res => {
            if (res.code == 1000) {
              this.faultOwnerList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.faultOwnerList = [];
      }
    },
    querWxConfigList() {
      getWxConfigList({ status: 0 }).then(res => {
        if (res.code === 1000) {
          this.wxConfigList = res.data || [];
        }
      });
    },
    // 新增/修改菜单
    onSave() {
      this.$refs.addForm?.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.addForm));
          data.invoiceLimit = data.invoiceLimit
            ? data.invoiceLimit * 100
            : null;
          data.oneInvoiceLimit = data.oneInvoiceLimit
            ? data.oneInvoiceLimit * 100
            : null;
          if (this.isEdit) {
            insertCompany(data).then(res => {
              if (res.code === 1000) {
                this.$message({
                  type: "success",
                  showClose: true,
                  message: "新增成功"
                });
                this.closeDia(res.code === 1000);
              } else {
                this.$message({
                  type: "error",
                  showClose: true,
                  message: res.msg
                });
                this.$emit("onLoading", false);
              }
            });
          } else {
            updateCompany(data).then(res => {
              if (res.code === 1000) {
                this.$message({
                  type: "success",
                  showClose: true,
                  message: "修改成功"
                });
                this.closeDia(res.code === 1000);
              } else {
                this.$message({
                  type: "error",
                  showClose: true,
                  message: res.msg
                });
                this.$emit("onLoading", false);
              }
            });
          }
        }
      });
    },
    closeDia(flag) {
      this.$emit("close", flag);
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: "XTLB" }).then(res => {
        if (res.code === 1000) {
          this.systemList = res.data.XTLB;
        }
      });
    }
  },
  mounted() {
    console.log("333....",this.addForm);
    this.getDicts();
    this.querWxConfigList();
  }
};
</script>

<style lang="scss" scoped>
.add-business {
  @include themify() {
    .modify {
      color: themed("n6");
      margin-left: 8px;
      &.active {
        color: themed("b4");
      }
    }
  }
  .invoice-limit-input {
    .el-input-group {
      vertical-align: middle;
    }
    ::v-deep .el-input__inner {
      width: 125px;
    }
  }
}
</style>
